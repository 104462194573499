import * as React from "react"
import { getSrc } from "gatsby-plugin-image"

import { format } from "date-fns"
import { parseISO } from "date-fns/esm"
import { de } from "date-fns/locale"

import Box from "../components/box"
import Breadcrumb from "../components/breadcrumb"
import Grid from "../components/grid"
import Layout from "../layouts/default"
import PageTitle from "../components/page-title"
import Paragraph from "../components/paragraph"
import Seo from "../components/seo"
import Stack from "../components/stack"
import Text from "../components/text"
import VideoPlayer from "../components/video-player"

function LiveView({ live }) {
  const date = live.meta.date ? parseISO(live.meta.date) : undefined

  return (
    <Layout backdrop="videos">
      <Seo
        title={live.name}
        description={live.description}
        image={getSrc(live.meta.cover.childImageSharp)}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Turm-TV",
              link: "/turm-tv",
            },
            {
              title: "Live",
              link: "/live",
            },
            {
              title: live.name,
              link: live.nameSlug,
            },
          ]}
        />
        <PageTitle>{live.name}</PageTitle>
        <Grid columns={[1, 1, 2]} reverse={[false, false, true]} space={0}>
          <Box height="100%">
            <VideoPlayer src={live.meta.src} poster={live.meta.cover} />
          </Box>
          <Box p={[6, 6, 8, 12]} bg="muted" height="100%">
            <Stack space={6}>
              <Paragraph size={[3, 3, 3, 4]}>{live.description}</Paragraph>
              {date ? (
                <Text sans={true}>
                  {format(date, "dd. MMMM yyyy", { locale: de })}
                </Text>
              ) : null}
            </Stack>
          </Box>
        </Grid>
      </Stack>
    </Layout>
  )
}

export default LiveView
